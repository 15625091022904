import * as React from 'react';
import { Footer, NavMenu, PageTitle } from '../components/shared';
import { Container } from 'react-bootstrap';

const NotFoundPage = () => (
  <>
    <PageTitle />
    <NavMenu solidColor notFixed />
    <Container>
      <div style={{ fontSize: 70, marginTop: 50 }}>SORRY, THAT PAGE CAN'T BE FOUND. (404 ERROR)</div>
    </Container>
    <Footer sticky />
  </>
);

export default NotFoundPage;
